// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {


    apiKey: "AIzaSyCiqaSIb9CLCru9Hf6MOPn717dW5zqng40",
    authDomain: "tele-moji.firebaseapp.com",
    projectId: "tele-moji",
    storageBucket: "tele-moji.appspot.com",
    messagingSenderId: "345598410468",
    appId: "1:345598410468:web:374cac7070e45f67e7b6f1",
    measurementId: "G-31Q7SJNY5W"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
