import { Component } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { initializeApp } from '@firebase/app';
import { Auth, getAuth} from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ahhmoji';
  constructor(private router:Router) {
    const firebaseApp = initializeApp(environment.firebaseConfig);
    const auth = getAuth();
    auth.onAuthStateChanged((user) =>{
      if(user){
        console.log('found a user', user);
        this.router.navigate(['advent-list']);

      } else{
        console.log('no user found')
        this.router.navigate(['signin']);
      }
    }, err =>{
      console.log('onAuthStatChanged error', err)
    })
  }
}
