import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdventListComponent } from './components/advent-list/advent-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignInComponent } from './components/sign-in/sign-in.component';


const routes: Routes = [
  {
    path:'',
    redirectTo:'signin',
    pathMatch:'full'
  },
  {
    path:'signin',
    component:SignInComponent
  },
  {
    path:'dashboard',
    component:DashboardComponent
  },
  {
    path:'advent-list',
    component:AdventListComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
