import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

import { getDocs , getFirestore, collection} from '@firebase/firestore';
import { AdventDay } from 'src/app/interfaces/advent-day.interface';

@Component({
  selector: 'app-advent-list',
  templateUrl: './advent-list.component.html',
  styleUrls: ['./advent-list.component.scss']
})
export class AdventListComponent implements OnInit {

  db = null;
  adventDays: AdventDay[] = [];
  constructor(private authService:AuthService) { 
    this.db = getFirestore();
  
  }

  async ngOnInit(): Promise<void> {

    var adventDaysFromStorage = localStorage.getItem('adventDays');
    if(adventDaysFromStorage) {
      console.log('data: storage');
      var items = JSON.parse(adventDaysFromStorage);
      console.log(items);
      items.forEach((el:AdventDay) => {
        this.adventDays.push(el);
      });
    } else {
      console.log('data: firebase')
      const aventDayCollRef = collection(this.db, 'adventday');
      const adventListSnap = await getDocs(aventDayCollRef);
      adventListSnap.forEach(x =>{
        console.log('data:', x.data(), x.id);
        this.adventDays.push({day:x.data().day, img: x.data().img, id: x.id});
      });
      this.adventDays.sort((n1,n2) => n1.day - n2.day);
      localStorage.setItem('adventDays', JSON.stringify(this.adventDays));
    }

   
   
  }
  signout(){
    this.authService.signOut();
  }
}
