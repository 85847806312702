import { NgZone } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, GoogleAuthProvider, signInWithPopup, getAuth} from '@firebase/auth';

@Injectable()
export class AuthService {

  constructor(private router: Router, private ngZone: NgZone) {
   }

  signInWithGoogle(){
    const auth = getAuth();
    const googleProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleProvider).then((result) =>{
      console.log('sign in result', result.user);
      this.router.navigate(['dashboard']);
    }).catch(err =>{
      console.log('google auth error', err)
    })
  }

  signOut(){
    console.log('signing out');
    localStorage.clear();
    const auth = getAuth();
    auth.signOut();
  }
}
